import { React } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';
import NewsItem from '../components/NewsItem';

const NEWS = gql`
{
    news(pagination: { page:1, pageSize: 30 } sort: "createdAt:asc") { 
      data {
        id
        attributes {
          Title
          publishedAt
          Slug
          Image {
            data{
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

function HeroSection() {
  const { loading, error, data } = useQuery(NEWS);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  const newsItemCount = data.news.data.length;

  return (
    <Box
      sx={{
        height: '50vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'background.light',
      }}
      display="flex"
    >
      <Box
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          display: 'flex',
          alignItems: 'flex-start',
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
        }}
      >
        <Typography
          component={motion.h1}
          sx={{

            display: 'flex',
            flexWrap: 'wrap',
          }}
          variants={AnimationVariants.enterDown}
          initial="exit"
          animate="enter"
          transition={{
            staggerChildren: 0.1,
            delayChildren: 0.3,
          }}
        >
          {StaggerText('News', 'h1')}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          variants={AnimationVariants.fadeIn}
          initial="exit"
          animate="enter"
          component={motion.p}
          transition={{
            delay: 0.6,
          }}
        >
          {newsItemCount}
        </Typography>
      </Box>

    </Box>
  );
}

function News() {
  const { loading, error, data } = useQuery(NEWS);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <>
      <Helmet>
        <title>News - Indesign Media Asia Pacific</title>
        <meta name="description" content="The lastest news and announcements from Indesign Media Asia Pacific" />
      </Helmet>
      <HeroSection />
      <Box
        sx={{
          pt: 8,
          pb: 16,
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          margin: '0 auto',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: 'repeat(1,1fr)',
            md: 'repeat(3,1fr)',
          },
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        intial="exit"
        animate="enter"
        transition={{
          delay: 1.5,
        }}
      >
        {
          data.news.data.map((news) => (
            <NewsItem
              key={news.id}
              title={news.attributes.Title}
              date={news.attributes.publishedAt}
              imgSrc={`http://cms.indesign.com.au${news.attributes.Image.data.attributes.url}`}
              linkTo={`/news/${news.attributes.Slug}`}
            />
          ))
        }
      </Box>
    </>
  );
}

export default Transition(News);
