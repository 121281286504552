import { AnimatePresence } from 'framer-motion';
import React from 'react';
import {
  Outlet, Route, Routes, useLocation,
} from 'react-router-dom';
import Home from '../../pages/Home';
import { Shell } from '../Layouts';
import Company from '../../pages/Company';
import Services from '../../pages/Services';
import News from '../../pages/News';
import Brands from '../../pages/Brands';
import Article from '../../pages/Article';
import MediaKits from '../../pages/MediaKits';
import Contact from '../../pages/Contact';
import Brand from '../../pages/Brand';
import FourOhFour from '../../pages/404';

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={(
            <Shell>
              <Outlet />
            </Shell>
            )}
        >
          <Route
            index
            element={(
              <Home />
          )}
          />
          <Route
            path="/company"
            element={(
              <Company />
          )}
          />
          <Route
            path="/services"
            element={(
              <Services />
          )}
          />
          <Route
            path="/news"
            element={(
              <News />
          )}
          />
          <Route
            path="/brands"
            element={(
              <Brands />
          )}
          />
          <Route
            path="/brands/:id"
            element={(
              <Brand />
          )}
          />
          <Route
            path="/news/:id"
            element={(
              <Article />
          )}
          />
          <Route
            path="/media-kit"
            element={(
              <MediaKits />
          )}
          />
          <Route
            path="/contact"
            element={(
              <Contact />
          )}
          />
          <Route
            path="*"
            element={<FourOhFour />}
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
}

export default AnimatedRoutes;
