import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { React } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';

function ServiceItem({ src }) {
  return (
    <Box
      component="li"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '350px',
        maxWidth: '350px',
        height: '350px',
        background: 'white',
        flexShrink: 0,
        borderRadius: 2,
      }}
    >
      <Box
        component="img"
        sx={{
          display: 'block',
          width: '150px',
          height: 'auto',
        }}
        src={src}
      />
    </Box>
  );
}

function ServicesCarousel() {
  const {
    scrollRef, next, prev, pages, activePageIndex,
  } = useSnapCarousel();
  return (
    <>
      <Box
        component="ul"
        ref={scrollRef}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          overflow: 'overlay',
          position: 'relative',
          scrollbarWidth: 'none',
          pl: 0,
          '& > *': {
            mr: 2,
          },
          '& > *:last-of-type': {
            mr: {
              xs: 2,
              md: 4,
              lg: 8,
            },
          },
          '&::-webkit-scrollbar': {
            height: 8,
            display: 'none',
          },
          '&::-webkit-scrollbar:hover': {
            height: 8,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'primary.light',
            display: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'block',
            backgroundColor: 'transparent',
            width: {
              xs: 16,
              md: 32,
              lg: 64,
            },
            height: '100%',
          }}
        />
        <ServiceItem
          src="/images/logos/architectural.png"
        />
        <ServiceItem
          src="/images/logos/autex.png"
        />
        <ServiceItem
          src="/images/logos/brand-logo-verosol.jpg"
        />
        <ServiceItem
          src="/images/logos/caroma.jpg"
        />
        <ServiceItem
          src="/images/logos/electrolux.png"
        />
        <ServiceItem
          src="/images/logos/godfrey.png"
        />
        <ServiceItem
          src="/images/logos/gyprock.png"
        />
        <ServiceItem
          src="/images/logos/images.png"
        />
        <ServiceItem
          src="/images/logos/Logo_VZug.png"
        />
        <ServiceItem
          src="/images/logos/millerknoll.png"
        />
        <ServiceItem
          src="/images/logos/neolith.png"
        />
        <ServiceItem
          src="/images/logos/network.png"
        />
        <ServiceItem
          src="/images/logos/siniat.png"
        />
        <ServiceItem
          src="/images/logos/stormtech.png"
        />
        <ServiceItem
          src="/images/logos/stylecraft.png"
        />
        <ServiceItem
          src="/images/logos/sub-zero-wolf.png"
        />
        <ServiceItem
          src="/images/logos/tco-living-edge-logo.gif"
        />
        <ServiceItem
          src="/images/logos/zenith.png"
        />
        <ServiceItem
          src="/images/logos/zip.jpg"
        />
      </Box>
      <Box
        sx={{
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            '& > *:not(:last-child)': {
              mr: 2,
            },
          }}
        >
          <IconButton
            variant="solid"
            aria-label="scroll forward to see more clients"
            color="primary"
            onClick={() => prev()}
            disabled={activePageIndex <= 0}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            variant="solid"
            aria-label="scroll forward to see more clients"
            color="primary"
            onClick={() => next()}
            disabled={activePageIndex >= pages.length - 1}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default ServicesCarousel;
