import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { React } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';

function ReviewItem({
  name, company, imgSrc, message,
}) {
  return (
    <Box
      component="li"
      sx={{
        borderRadius: 1,
        p: 4,
        width: {
          xs: 550,
          md: 600,
        },
        height: {
          xs: 600,
          md: 376,
        },
        backgroundColor: 'background.default',
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mb: 8,
          height: 48,
          maxHeight: 48,
        }}
      >
        <Box>
          <Typography variant="subtitle1" component="p">{name}</Typography>
          <Typography variant="body1" component="p" color="textSecondary">{company}</Typography>
        </Box>
        <Box
          component="img"
          src={imgSrc}
          sx={{
            maxWidth: 80,
            maxHeight: 48,
            width: 'auto',
            height: 'auto',
            objectFit: 'contain',
            objectPosition: 'top',
          }}
        />
      </Box>
      <Typography
        variant="body1"
        component="p"
      >
        {message}
      </Typography>
    </Box>
  );
}

function ReviewsCarousel() {
  const {
    scrollRef, next, prev, pages, activePageIndex,
  } = useSnapCarousel();
  return (
    <>
      <Box
        component="ul"
        ref={scrollRef}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          overflow: 'overlay',
          position: 'relative',
          pl: 0,
          listStyle: 'none',
          minHeight: 376,
          scrollbarWidth: 'none',
          '& > *': {
            mr: 2,
          },
          '& > *:last-of-type': {
            mr: {
              xs: 2,
              md: 4,
              lg: 8,
            },
          },
          '&::-webkit-scrollbar': {
            height: 1,
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar:hover': {
            height: 1,
            backgroundColor: 'transparent',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          sx={{
            display: 'block',
            backgroundColor: 'transparent',
            width: {
              xs: 16,
              md: 32,
              lg: 64,
            },
            height: '100%',
          }}
        />
        <ReviewItem
          name="Nic Naes"
          company="Managing Director, V-ZUG ANZ"
          imgSrc="/images/logos/Logo_VZug.png"
          message="As a longstanding reader, and collector, of Habitus Magazine I made an unsolicited approach to Raj and the team at InDesign Media shortly after I assumed my current role. The team at Habitus understood the brief very well and undertook a brand assessment to establish our baseline before we collaboratively designed a multi-channel approach to content generation, case studies, digital, events, print and more, including to co-hosting annual event at Milano Design week each year. The team and I found the team at Habitus very easy, and fun, to work with, the understanding of our respective businesses is growing stronger for each year, Habitus is an integral part of the V-ZUG connection with our audience, locally in Australia, and internationally with our team in Zug."
        />
        <ReviewItem
          name="Troy Creighton"
          company="Managing Director, Stormtech"
          imgSrc="/images/logos/stormtech.png"
          message="The level of expertise demonstrated by Architecture & Design is truly commendable. They possess a deep understanding of the industry as a whole, which in turn provides Stormtech with invaluable insights and solutions tailored to our specific needs. The Architecture & Design team's attention to detail and willingness to go the extra mile showcase their genuine passion for delivering outstanding results"
        />
        <ReviewItem
          name="Jocelyn Szot"
          company="Marketing Manager, Nover"
          imgSrc="/images/logos/nover.jpg"
          message="I have been working with the team at Architecture & Design over the last four years and we have seen an increase in our website traffic, increased leads, and an overall favourable brand positioning for Nover from our whitepapers, online and e-marketing/advertising. The Architecture & Design team are extremely professional and results driven. We appreciate all their efforts in helping Nover grow within the industry."
        />
        <ReviewItem
          name="Angela Mihok"
          company="Commercial Marketing Manager, CSR"
          imgSrc="/images/logos/csr.jpeg"
          message="We chose Architecture & Design Network because no other platform offered all the tools and support needed by the building industry in one place. Our focus is on building value for our customers by delivering industry-leading materials through the Architecture & Design Network."
        />
        <ReviewItem
          name="Jade Allerby"
          company="Marketing Manager, Network Architectural"
          imgSrc="/images/logos/network.png"
          message="We’ve been a client of Architecture & Design for several years now and they continue to be an important part of our marketing strategy. We enjoy working with the team and it feels like a partnership. They take the time to understand our business and products and work with us to develop campaign plans aligned to our goals. We value their input as they are a trusted resource for the architecture, design and construction industry."
        />
        <ReviewItem
          name="Melody Org"
          company="Marketing Manager, SVC & Anston"
          imgSrc="/images/logos/SVC-Logo.jpg"
          message="The A&D network offers a great way for both SVC and Anston to promote our locally made products to our diverse client base of architects, designers, builders and developers. It provides an opportunity to increase our online presence and is a credible and useful platform with plenty of relevant content for all companies operating within architecture, building and construction."
        />
        <ReviewItem
          name="Laura Zahariou"
          company="Head of Marketing, HVG"
          imgSrc="/images/logos/hvg.png"
          message="A&D’s commitment to delivering quality content and providing a platform for architects and designers is truly commendable. The insights into the industry and dedication to showcasing innovative design concepts have made our partnership rewarding. The media offerings provided by A&D and the team are of high calibre, catering perfectly to the needs of our target audience. The exposure our projects receive through A&D platform led to a significant increase in visibility and engagement within our industry. The results speak for themselves – a notable impact on brand recognition and a positive influence on our market presence. I highly recommend Architecture & Design to any organisation seeking a dynamic and effective media partner."
        />
      </Box>
      <Box
        sx={{
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          mt: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            '& > *:not(:last-child)': {
              mr: 2,
            },
          }}
        >
          <IconButton
            variant="solid"
            aria-label="scroll forward to see more clients"
            color="primary"
            onClick={() => prev()}
            disabled={activePageIndex <= 0}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            variant="solid"
            aria-label="scroll forward to see more clients"
            color="primary"
            onClick={() => next()}
            disabled={activePageIndex >= pages.length - 1}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}

export default ReviewsCarousel;
