import { React } from 'react';
import Moment from 'react-moment';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function NewsItem({
  title, date, imgSrc, linkTo,
}) {
  return (
    <Box
      component={Link}
      to={linkTo}
      sx={{
        mb: 8,
        '& .news-image:hover': {
          scale: '1.05',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          position: 'relative',
          overflow: 'hidden',
          mb: 2,
        }}
      >
        <img
          className="news-image"
          src={imgSrc}
          alt=""
          style={{
            transition: '0.3s',
            width: '100%',
            height: 'auto',
          }}
        />
      </Box>
      <Typography variant="subtitle1" component="p" sx={{ mb: 1, color: 'text.primary' }}>{title}</Typography>
      <Typography variant="body1" component="p" color="textSecondary"><Moment format="Do MMMM YYYY" date={date} /></Typography>
    </Box>
  );
}

NewsItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default NewsItem;
