import { React, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion, useTransform, useScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

function HeroSection() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
  const textY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
      }}
      display="flex"
    >
      <Box
        component={motion.h1}
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          width: {
            xs: '100%',
            md: '70%',
          },
          display: 'flex',
          flexWrap: 'wrap',
        }}
        style={{ y: textY }}
        variants={AnimationVariants.enterDown}
        initial="exit"
        animate="enter"
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.3,
        }}
      >
        {StaggerText('From Australia to Singapore, the Indesign team is driven to lead the industry.', 'h1')}
      </Box>
      <motion.div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          y: backgroundY,
          inset: 0,
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            zIndex: 1,
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.70) 71%), linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0) 9%)',
          }}
        />
        <motion.img
          src="/images/company/hero.jpg"
          alt=""
          style={{
            position: 'absolute',
            inset: 0,
            margin: 'auto',
            width: 'auto',
            height: '100vh',
            zIndex: 0,
          }}
          initial={{ scale: 1.3 }}
          animate={{ scale: 1.15 }}
          transition={{
            ease: 'anticipate',
            duration: 1,
          }}
        />
      </motion.div>

    </Box>
  );
}

function About() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '35%']);
  return (
    <Box
      component={motion.div}
      ref={containerRef}
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: 16,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
      }}
    >
      <Typography
        variant="subtitle1"
        component="p"
        color="textSecondary"
        sx={{
          mb: 4,
        }}
      >
        We are Indesign Media
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Typography
          component={motion.p}
          variant="h5"
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
          sx={{
            width: '100%',
          }}
        >
          {StaggerText('Your Connection to the World of Architecture, Design & Travel.', 'h5')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            ml: {
              xs: 0,
              sm: 8,
            },
            mt: {
              xs: 8,
              sm: 0,
            },
          }}
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          <Typography
            component={motion.div}
            variants={AnimationVariants.fadeIn}
            variant="body1"
            sx={{
              mb: 4,
            }}
          >
            Since inception in the year 2000 by CEO Raj and Kavita Nandan, Indesign Media Asia Pacific is the leading resource on Architecture and Design in the Asia Pacific. With a focus on innovation, the Indesign brand encompasses print, digital, events and specialised content, offering access to the widest and most engaged audience in the region. Speaking to architects, designers, specifiers, property developers, design lovers and more, Indesign Media has cornered all areas of the market.
          </Typography>
          <Typography
            component={motion.div}
            variants={AnimationVariants.fadeIn}
            variant="body1"
            sx={{
              mb: 8,
            }}
          >
            From our offices in Sydney, Melbourne, Singapore and Hong Kong, we lead the conversations the industry is having, informing dialogue with our commitment to excellence and innovation.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ width: 'fit-content !important' }}
            component={motion.button}
            variants={AnimationVariants.fadeIn}
          >
            Get in Touch

          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 16,
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          '& > *:not(:last-child)': {
            mb: {
              xs: 2,
              md: 0,
            },
            mr: {
              xs: 0,
              md: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: {
              xs: 500,
              md: 350,
            },
            aspectRatio: '3 / 4',
            width: {
              xs: '100%',
              md: '30%',
            },
            mb: {
              xs: 16,
              md: 0,
            },
          }}
        >
          <motion.img
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: 'auto',
              margin: 'auto',
              scale: 2,
              y: backgroundY,
              inset: 0,
            }}
            src="https://www.indeawards.com/wp-content/uploads/2022/06/The-Living-Space-Bermagui-Beach-House-Jack-Mounsey.jpg"
            alt=""
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            aspectRatio: '3 / 4',
            width: {
              xs: '100%',
              md: '70%',
            },
          }}
        >
          <motion.img
            style={{
              position: 'absolute',
              bottom: 0,
              width: 'auto',
              height: '100%',
              scale: 1.5,
              y: backgroundY,
              inset: 0,
            }}
            src="https://www.indeawards.com/wp-content/uploads/2022/06/The-Living-Space-Bermagui-Beach-House-Jack-Mounsey-3.jpg"
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
}

function ChooseUsItem({
  title, description, imgSrc, position,
}) {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '70%',
        },
        ml: position === 'right' ? 'auto' : 'unset',
        mr: position === 'left' ? 'auto' : 'unset',
      }}
      ref={containerRef}
      component={motion.div}
      initial="exit"
      whileInView="enter"
      viewport={{ once: 'true' }}
      variants={AnimationVariants.fadeIn}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          mb: 4,
          aspectRatio: '16 / 9',
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
      >
        <motion.img
          style={{
            position: 'absolute',
            width: '100%',
            height: 'auto',
            margin: 'auto',
            bottom: 0,
            left: 0,
            right: 0,
            y: backgroundY,
          }}
          src={imgSrc}
          alt=""
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          '& > *:not(:last-child)': {
            mb: {
              xs: 2,
              md: 0,
            },
            mr: {
              xs: 0,
              md: 2,
            },
          },
        }}
      >
        <Typography
          variant="h5"
          component={motion.p}
          variants={AnimationVariants.fadeIn}
          sx={{ width: '100%' }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component={motion.p}
          variants={AnimationVariants.fadeIn}
          sx={{ width: '100%' }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

ChooseUsItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};

function ChooseUs() {
  return (
    <Box
      sx={{
        position: 'relative',
        pb: 32,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        '& > *:not(:last-child)': {
          mb: 16,
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            md: '70%',
          },
        }}
      >
        <Typography
          variant="h5"
          component={motion.p}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: true }}
          transition={{ delay: 0.3 }}
        >
          We strive to connect like-minded brands to our dedicated and devoted audiences via our dynamic ecosystem of products and services across content strategy, print, digital, events, activations and further bespoke solutions. Here are some reasons you should choose us.
        </Typography>
      </Box>
      <ChooseUsItem
        title="We’ve got experience"
        description="After over two decades working in the industry, Indesign Media has the knowledge and experience to know what works. We would be delighted to work with you and share this knowledge to ensure that your brand is successful in industry communication and connection."
        imgSrc="images/company/person-talking-blue.jpg"
        position="right"
      />
      <ChooseUsItem
        title="We are focused on growth and innovation"
        description="Innovation is in our DNA. Let us help your company to grow and change with the industry, ensuring you are always ahead of the curve, part of leading industry conversations and being proactive when it comes to industry changes. With us, you can set the trends rather than follow them. "
        imgSrc="images/company/imagination.jpg"
        position="left"
      />
      <ChooseUsItem
        title="We are trusted in the industry"
        description="We have worked hard to become a reliable and trusted brand for the architecture and design and travel industries throughout the Indo-Pacific. As the go-to resource for architecture and design and luxury travel, working with us gives your brand the best chance to reach your target audience and ensure they listen to what you have to say. "
        imgSrc="images/company/person-talking-mic.jpg"
        position="right"
      />
      <ChooseUsItem
        title="We have a loyal audience base that is always increasing"
        description="Our audience base is always growing and we want to share this increasing reach with your brand. This audience is also one that is loyal to us and our partners, making all the difference when it comes to creating a successful brand ensuring relationships convert to ROI. "
        imgSrc="images/company/collingwood-crowd.jpg"
        position="left"
      />
      <ChooseUsItem
        title="We believe in omnichannel marketing"
        description="At Indesign, we know that having multiple touchpoints to reach your audience is the best way to grow and ensure conversions to sales. Partner with us for access to our multiple leading channels across the industry including print, digital and events to create and share your story. "
        imgSrc="images/company/books.jpg"
        position="right"
      />
    </Box>
  );
}

function Company() {
  return (
    <>
      <Helmet>
        <title>Company - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
      <About />
      <ChooseUs />
    </>
  );
}

export default Transition(Company);
