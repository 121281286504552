import {
  React, useState, useRef,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box, Typography, Button,
} from '@mui/material';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useQuery, gql } from '@apollo/client';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import AnimationVariants from '../components/AnimationVariants';
import ServicesCarousel from '../components/ServicesCarousel';
import ReviewsCarousel from '../components/ReviewsCarousel';
import NewsItem from '../components/NewsItem';
import BrandItem from '../components/BrandItem';
import { StaggerText } from '../components/Layouts';
import Transition from '../components/Transition';

const NEWS = gql`
{
    news(pagination: { page:1, pageSize: 3 } sort: "createdAt:desc") { 
      data {
        id
        attributes {
          Title
          publishedAt
          Slug
          Image {
            data{
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const BRANDS = gql`
  {
    brands(pagination: { page:1, pageSize: 30 } sort: "Title:asc") {
      data {
        id
        attributes {
          Title
          Slug
          Image {
            data{
              attributes {
                url
              }
            }
          }
          product_type{
            data{
              attributes{
                Title
              }
            }
          }
          product_industry{
            data{
              attributes{
                Title
                Description
                industrySlug
              }
            }
          }
        }
      }
    }
    productIndustries{
      data{
        id
        attributes{
          Title
          industrySlug
        }
      }
    }
  }
`;

function HeroSection() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
  const textY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
      }}
      display="flex"
    >
      <Box
        component={motion.h1}
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          width: {
            xs: '100%',
            md: '70%',
          },
          display: 'flex',
          flexWrap: 'wrap',
        }}
        style={{ y: textY }}
        variants={AnimationVariants.enterDown}
        initial="exit"
        animate="enter"
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.5,
        }}
      >
        {StaggerText('The epitome of influence in the realms of design, architecture, art, and luxury.', 'h1')}
      </Box>
      <motion.div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          y: backgroundY,
          inset: 0,
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            zIndex: 1,
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.70) 71%), linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0) 9%)',
          }}
        />
        <motion.video
          src="images/home/hero-background-video.mp4"
          alt=""
          type="video/mp4"
          autoPlay
          muted
          loop
          style={{
            position: 'absolute',
            width: 'auto',
            height: '100vh',
            zIndex: 0,
          }}
          initial={{ scale: 1.3 }}
          animate={{ scale: 1.15 }}
          transition={{
            ease: 'anticipate',
            duration: 1,
          }}
        />
      </motion.div>

    </Box>
  );
}

function About() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end end'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '25%']);
  return (
    <Box
      component={motion.div}
      ref={containerRef}
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: {
          xs: 16,
          sm: 32,
        },
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        backgroundColor: 'background.default',
      }}
    >
      <Box
        display="flex"
        sx={{
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          mb: {
            xs: 16,
            md: 32,
          },
        }}
      >
        <Box
          sx={{
            mr: 8,
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              sm: '70%',
              md: '50%',
            },
            mb: {
              xs: 16,
              md: 0,
            },
          }}
        >
          <Typography
            variant="body1"
            component="p"
            color="textSecondary"
            sx={{
              mb: 4,
            }}
          >
            About Us
          </Typography>
          <Box
            sx={{
              mb: {
                xs: 16,
                md: 0,
              },
            }}
            component={motion.div}
            variants={AnimationVariants.enterDown}
            initial="exit"
            whileInView="enter"
            viewport={{ once: 'true' }}
            transition={{
              staggerChildren: 0.1,
              delayChildren: 0.2,
            }}
          >
            {StaggerText('With a focus on growth and innovation, Indesign Media can take your brand to new heights.', 'h5')}
          </Box>
          <Box
            display="flex"
            sx={{
              mt: 'auto',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
            initial="exit"
            whileInView="enter"
            viewport={{ once: 'true' }}
            transition={{
              staggerChildren: 0.1,
              delayChildren: 0.2,
            }}
          >
            <Box
              sx={{
                mr: 8,
                width: '100%',
                mb: {
                  xs: 8,
                  md: 0,
                },
              }}
              component={motion.div}
              variants={AnimationVariants.fadeIn}
            >
              <Typography variant="h1" component="p" sx={{ color: 'primary.light' }}>20+</Typography>
              <Typography variant="subtitle1" component="p" color="textSecondary">
                Years in the Architecture,
                {' '}
                <br />
                Design & Travel space.
              </Typography>
            </Box>
            <Box
              sx={{ width: '100%' }}
              component={motion.div}
              variants={AnimationVariants.fadeIn}
            >
              <Typography variant="h1" component="p" sx={{ color: 'primary.light' }}>2,100+</Typography>
              <Typography variant="subtitle1" component="p" color="textSecondary">
                Amazing Clients
                {' '}
                <br />
                We Have Worked With
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: {
              xs: '100%',
              sm: '70%',
              md: '50%',
            },
            ml: {
              xs: 'auto',
              md: 0,
            },
            aspectRatio: '3 / 4',
          }}
        >
          <motion.img
            style={{
              position: 'absolute',
              bottom: 100,
              margin: 'auto',
              width: '100%',
              height: 'auto',
              left: 0,
              right: 0,
              y: backgroundY,
            }}
            src="images/home/magazine-highlight.jpg"
            alt="A shot from behind of a person holding an Indesign Media Magazine"
          />
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          flexDirection: {
            xs: 'column-reverse',
            md: 'row',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            aspectRatio: '16 / 9',
            width: {
              xs: '100%',
              md: '70%',
            },
          }}
        >
          <motion.img
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: '100%',
              height: 'auto',
              scale: 1.5,
              y: backgroundY,
            }}
            src="images/home/talking-panel.jpg"
          />
        </Box>
        <Box
          sx={{
            ml: {
              xs: 'auto',
              md: 8,
            },
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              sm: '60%',
              md: '30%',
            },
            mb: {
              xs: 16,
              md: 0,
            },
          }}
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              mb: 4,
            }}
            component={motion.p}
            variants={AnimationVariants.fadeIn}
          >
            Since inception in the year 2000 we have worked to create a brand that is reliable, trusted and leading amongst architecture, design, sustainability and travel industries. With a truly Indo-Pacific reach, an array of global connections and a constant focus on innovation, it’s no wonder we thrive on leading our industry’s conversations.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mb: 8,
            }}
            component={motion.p}
            variants={AnimationVariants.fadeIn}
          >
            As the most trusted, go-to architecture, design and travel resource with a wide reach in all areas of the industry across the Indo-Pacific region, we are here to offer your brand unparalleled strategic communication opportunities.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ width: 'fit-content !important' }}
            component={Link}
            variants={AnimationVariants.fadeIn}
            to="/company"
          >
            More About Us

          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function Services() {
  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: {
          xs: 16,
          sm: 32,
        },
        zIndex: 3,
        backgroundColor: 'background.light',
      }}
    >
      <Box
        display="flex"
        sx={{
          mb: 16,
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
        }}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              md: '50%',
            },
            mr: 8,
            mb: {
              xs: 8,
              md: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{
              mb: 4,
            }}
          >
            Our Services
          </Typography>
          <Box
            sx={{
              mb: 8,
            }}
            component={motion.div}
            variants={AnimationVariants.enterDown}
            initial="exit"
            whileInView="enter"
            viewport={{ once: 'true' }}
            transition={{
              staggerChildren: 0.1,
              delayChildren: 0.2,
            }}
          >
            {StaggerText('We offer services including content strategy, advertising, content amplification, lead generation and more to suit your needs.', 'h5')}
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            component={Link}
            to="/contact"
          >
            Get in Touch
          </Button>
        </Box>
        <Box
          sx={{
            mt: 6,
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          <Accordion
            expanded={expanded === 'media'}
            onChange={handleChange('media')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary
              aria-controls="media-content"
              id="media-header"
              expandIcon={<ArrowForward />}
              sx={{
                paddingTop: 1,
              }}
            >
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                01
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>
                Media Campaigns
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">
                Our media campaigns set us apart in the design market through their innovative approach, meticulous research, and deep understanding of our audience&apos;s preferences. We blend captivating visuals with insightful content, delivering a unique and memorable experience. By leveraging cutting-edge technologies and platforms, we ensure maximum reach and engagement, surpassing our competitors. Our campaigns resonate with the design community, sparking conversations and driving meaningful connections, ultimately solidifying our position as industry leaders in media excellence.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'content'}
            onChange={handleChange('content')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary aria-controls="content-content" id="content-header" expandIcon={<ArrowForward />}>
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                02
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>Content Amplification</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" component="p">
                The key to capturing the attention of your target audience is to ensure
                you’re reaching them through multiple touchpoints.
                At Indesign Media, we can help you create strategic cross
                platform media campaigns that allow you to get your story
                out to your target audience around the entire Indo-Pacific
                region. We can work with you to create campaigns that are
                tailored to your brand’s objectives and can increase
                redibility, consumer loyalty and sales.
                You’ve put in the work, now let us show it to the world.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'lead'}
            onChange={handleChange('lead')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary aria-controls="lead-content" id="lead-header" expandIcon={<ArrowForward />}>
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                03
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>Lead Generation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                We understand the importance of keeping your sales pipeline
                active and the difficulties in reaching the right audience
                to generate new leads. This is why we want to help you
                create leads that are interested in your brand so you can
                continue focusing on creating products they will love.
                Work with us today to expand your audience.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'events'}
            onChange={handleChange('events')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary aria-controls="events-content" id="events-header" expandIcon={<ArrowForward />}>
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                04
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>Events and Activations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Events are an ideal way to get more brand exposure and connections.
                With our knowledge and experience from over two decades of events,
                we can help you to wow your audience and ensure your activities create a
                positive lasting impression. With options of joining our industry leading
                events, or using our expert team to refine your own, get in touch to discuss
                your brand’s goals and how we can work together.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'marketing'}
            onChange={handleChange('marketing')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary aria-controls="marketing-content" id="marketing-header" expandIcon={<ArrowForward />}>
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                05
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>Content Marketing</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The marketing landscape is ever changing. For your brand to succeed,
                strategic communication is key. Partner with us to creatively identify the
                elements that make your brand unique and create a strategically crafted campaign
                to elevate your story. Amplified via the power of our cross-platform ecosystem,
                expertise and data – let us help you inform audience
                journeys and drive conversion behaviours.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'bespoke'}
            onChange={handleChange('bespoke')}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <AccordionSummary aria-controls="bespoke-content" id="bespoke-header" expandIcon={<ArrowForward />}>
              <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mr: 2 }}>
                06
              </Typography>
              <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>Bespoke Video Creation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our video creation service is tailored to your brand’s objectives.
                Our aim is to transform your content into video that will
                resonate with your target audience. We offer support from pre- to
                post-production with a team that is focused on creating engaging
                videos for your brand that can be used for social media content,
                digital advertising and more.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <ServicesCarousel />
    </Box>
  );
}

function Brands() {
  const shuffle = (arr) => [...arr].sort(() => Math.random() - 0.5);

  const { loading, error, data } = useQuery(BRANDS);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  const brandsArray = shuffle(data.brands.data);

  const brandsArray1 = brandsArray.slice(0, 2);

  const brandsArray2 = brandsArray.slice(2, 4);

  const brandsArray3 = brandsArray.slice(4, 6);

  return (
    <Box
      component={motion.div}
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: {
          xs: 16,
          sm: 32,
        },
        zIndex: 6,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },
          '& > *:not(:last-child)': {
            mb: {
              xs: 2,
              sm: 0,
            },
          },
        }}
      >
        <Box
          component={motion.div}
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          {StaggerText('Brands By Us', 'h5')}
        </Box>
        <Button
          to="/brands"
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{ delay: 0.5 }}
        >
          View Brands

        </Button>
      </Box>
      <Box
        display="flex"
        sx={{
          mt: 8,
        }}
      />
      <Box
        display="flex"
        sx={{
          mb: 8,
          '& > *:not(:last-child)': {
            mr: {
              xs: 0,
              md: 2,
            },
            mb: 8,
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {
          brandsArray1.map((brands) => (
            <BrandItem
              key={brands.id}
              title={brands.attributes.Title}
              type={brands?.attributes?.product_type?.data?.attributes?.Title}
              imgSrc={`http://cms.indesign.com.au${brands?.attributes?.Image?.data?.attributes?.url}`}
              linkTo={`/brands/${brands.attributes.Slug}`}
            />
          ))
        }
      </Box>
      <Box
        display="flex"
        sx={{
          mb: 8,
          '& > *:not(:last-child)': {
            mr: {
              xs: 0,
              md: 2,
            },
            mb: 8,
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {
          brandsArray2.map((brands) => (
            <BrandItem
              key={brands.id}
              title={brands.attributes.Title}
              type={brands?.attributes?.product_type?.data?.attributes?.Title}
              imgSrc={`http://cms.indesign.com.au${brands?.attributes?.Image?.data?.attributes?.url}`}
              linkTo={`/brands/${brands.attributes.Slug}`}
            />
          ))
        }
      </Box>
      <Box
        display="flex"
        sx={{
          '& > *:not(:last-child)': {
            mr: {
              xs: 0,
              md: 2,
            },
          },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {
          brandsArray3.map((brands) => (
            <BrandItem
              key={brands.id}
              title={brands.attributes.Title}
              type={brands?.attributes?.product_type?.data?.attributes?.Title}
              imgSrc={`http://cms.indesign.com.au${brands?.attributes?.Image?.data?.attributes?.url}`}
              linkTo={`/brands/${brands.attributes.Slug}`}
            />
          ))
        }
      </Box>
    </Box>
  );
}

function Reviews() {
  return (
    <Box
      sx={{
        pt: 8,
        pb: 16,
        backgroundColor: 'background.light',
      }}
    >
      <Box
        component={motion.div}
        variants={AnimationVariants.enterDown}
        initial="exit"
        whileInView="enter"
        viewport={{ once: 'true' }}
        transition={{
          staggerChildren: 0.1,
          delayChildren: 0.2,
        }}
        sx={{
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          mb: 8,
        }}
      >
        {StaggerText('Words from our Clients', 'h5')}
      </Box>
      <ReviewsCarousel />
    </Box>
  );
}

function Newsletter() {
  const { loading, error, data } = useQuery(NEWS);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        pt: 8,
        pb: 8,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: 8,
        }}
      >
        <Box
          component={motion.div}
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          {StaggerText('News', 'h5')}
        </Box>
        <Button
          to="/news"
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{ delay: 0.5 }}
        >
          More News
        </Button>
      </Box>
      <Box
        display="flex"
        sx={{
          margin: '0 auto',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: 'repeat(1,1fr)',
            md: 'repeat(3,1fr)',
          },
        }}
      >
        {
          data.news.data.map((news) => (
            <NewsItem
              key={news.id}
              title={news.attributes.Title}
              date={news.attributes.publishedAt}
              imgSrc={`http://cms.indesign.com.au${news.attributes.Image.data.attributes.url}`}
              linkTo={`/news/${news.attributes.Slug}`}
            />
          ))
        }
      </Box>
    </Box>
  );
}

function Home() {
  return (
    <>
      <Helmet>
        <title>Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
      {/* About Us Section */}
      <About />
      {/* Services Section */}
      <Services />
      {/* Work Section */}
      <Brands />
      {/* Review Section */}
      <Reviews />
      {/* Newsletter Section */}
      <Newsletter />
    </>
  );
}

export default Transition(Home);
