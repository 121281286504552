import { React, useState, useEffect } from 'react';
import {
  Box, Button, IconButton, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useScroll, motion } from 'framer-motion';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

function AnimatedLink({ title, href }) {
  return (
    <Box
      component={Link}
      to={href}
      sx={{
        height: 20,
        p: 0,
        overflow: 'hidden',
        position: 'relative',
        color: 'text.primary',
        '& > .link-container:hover': {
          transform: 'translateY(-21px)',
          color: 'text.secondary',
        },
      }}
    >
      <Box
        className="link-container"
        sx={{
          transition: '0.3s',
          transform: 'translateY(2px)',
          '& > *:not(:last-child)': {
            mb: 1,
          },
        }}
      >
        <Typography
          variant="body2"
          component="p"
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          component="p"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

AnimatedLink.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

function MobileMenu() {
  /* This is to allow users to scroll again once a user has clicked a link */
  function removePreventScrolling() {
    document.body.classList.remove('prevent-scrolling');
  }

  return (
    <Box
      sx={{
        zIndex: 999,
        backgroundColor: 'primary.main',
        height: 'calc(100% - 54px)',
        width: '100vw',
        position: 'fixed',
        top: '53px',
        left: 0,
        pt: 16,
        pb: 8,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },

      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          '& > *': {
            py: 2,
            color: 'text.primary',
          },
          '& > :first-child': {
            pt: 0,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/"
          sx={{
            borderBottom: '0.5px solid #FFFFFF49',
            width: '100%',
            display: 'block',
          }}
        >
          Home
        </Typography>
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/company"
          sx={{
            borderBottom: '0.5px solid #FFFFFF49',
            width: '100%',
            display: 'block',
          }}
        >
          Company
        </Typography>
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/services"
          sx={{
            borderBottom: '0.5px solid #FFFFFF49',
            width: '100%',
            display: 'block',
          }}
        >
          Services
        </Typography>
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/brands"
          sx={{
            borderBottom: '0.5px solid #FFFFFF49',
            width: '100%',
            display: 'block',
          }}
        >
          Brands
        </Typography>
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/news"
          sx={{
            borderBottom: '0.5px solid #FFFFFF49',
            width: '100%',
            display: 'block',
          }}
        >
          News
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 'auto',
          '& > *:not(:last-child)': {
            mr: 2,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/media-kit"
          sx={{
            display: 'block',
            color: 'text.secondary',
          }}
        >
          Media Kits
        </Typography>
        <Typography
          variant="subtitle1"
          component={Link}
          onClick={() => removePreventScrolling()}
          to="/contact"
          sx={{
            display: 'block',
            color: 'text.secondary',
          }}
        >
          Contact Us
        </Typography>
      </Box>
    </Box>
  );
}

function Nav() {
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);
  const [removeBg, setRemoveBg] = useState(true);
  const [displayMenu, setDisplayMenu] = useState(false);

  /* This is to display the mobile hamburger menu */
  function handleDisplayMenu() {
    setDisplayMenu(!displayMenu);
    if (displayMenu) {
      document.body.classList.remove('prevent-scrolling');
    } else {
      document.body.classList.add('prevent-scrolling');
    }
  }

  /** this onUpdate function will be called in the `scrollY.onChange` callback * */
  function updatePosition() {
    if (scrollY?.current < scrollY?.prev) {
      setHidden(false);
    } else if (scrollY?.current > 100 && scrollY?.current > scrollY?.prev) {
      setHidden(true);
    }
  }
  function updateBg() {
    if (scrollY?.current === 0 || scrollY?.current < 100) {
      setRemoveBg(true);
    } else {
      setRemoveBg(false);
    }
  }
  useEffect(() => scrollY.onChange(() => { updatePosition(); updateBg(); }));

  /** add this const * */
  const variants = {
    /** this is the "visible" key and it's correlating styles * */
    visible: { y: 0 },
    /** this is the "hidden" key and it's correlating styles * */
    hidden: { y: -54 },
  };
  return (
    <>
      <Box
        component={motion.header}
        animate={hidden ? 'hidden' : 'visible'}
        transition={{ duration: 0.3 }}
        variants={variants}
        sx={{
          display: {
            xs: 'flex',
            md: 'block',
          },
          height: 54,
          width: '100%',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 98,
          backgroundColor: removeBg ? 'transparent' : '#1B1B1B46',
          backdropFilter: removeBg ? 'none' : 'blur(30px)',
          borderBottom: removeBg ? 'none' : '0.5px solid #FFFFFF19',
          px: {
            xs: 0,
            md: 4,
            lg: 8,
          },
          pl: {
            xs: 2,
            md: 4,
          },
        }}
      >
        <Box
          sx={{
            maxWidth: 1920,
            ml: {
              xs: 0,
              md: 'auto',
            },
            mr: {
              xs: 0,
              md: 'auto',
            },
            mt: {
              xs: 0,
              md: '10px',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: {
              xs: 'unset',
              md: 'space-between',
              lg: 'unset',
            },
          }}
        >
          <Box
            sx={{
              width: {
                xs: 213,
                lg: '100%',
              },
              height: 'fit-content',
            }}
          >
            <Typography
              variant="subtitle2"
              component={Link}
              to="/"
              sx={{
                color: 'text.primary',
              }}
            >
              Indesign Media Asia Pacific.
            </Typography>
          </Box>
          <Box
            component="nav"
            sx={{
              width: {
                xs: 'auto',
                lg: '100%',
              },
              display: {
                xs: 'none',
                md: 'flex',
              },
              ml: {
                xs: 0,
                lg: 8,
              },
              justifyContent: {
                xs: 'unset',
                lg: 'space-between',
              },
            }}
          >
            <Box
              alignItems="center"
              sx={{
                display: {
                  md: 'flex',
                },
                '& > *:not(:last-child)': {
                  marginRight: 4,
                },
                mr: {
                  xs: 0,
                  md: 4,
                  lg: 0,
                },
              }}
            >
              <AnimatedLink title="Company" href="company" />
              <AnimatedLink title="Services" href="services" />
              <AnimatedLink title="Brands" href="brands" />
              <AnimatedLink title="News" href="news" />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                '& > *:not(:last-child)': {
                  marginRight: 4,
                },
              }}
            >
              <AnimatedLink title="Media Kit" href="media-kit" />
              <Button variant="contained" size="small" component={Link} to="contact">Contact Us</Button>
            </Box>
          </Box>
        </Box>

        {/* Smaller Screens Menu */}
        <Box
          component="nav"
          sx={{
            ml: 'auto',
            height: '100%',
            px: {
              xs: 2,
              lg: 8,
            },
            display: {
              xs: 'flex',
              md: 'none',
            },
            borderLeft: {
              xs: removeBg ? 'none' : '1px solid #FFFFFF19',
              md: 'none',
            },
            backgroundColor: displayMenu ? 'primary.main' : 'transparent',
          }}
          justifyContent="space-between"
        >
          <IconButton
            aria-label="Menu Button"
            onClick={() => handleDisplayMenu()}
          >
            {displayMenu
              ? (
                <CloseIcon
                  sx={{
                    color: 'text.primary',
                  }}
                />
              )
              : (
                <MenuIcon
                  sx={{
                    color: 'text.primary',
                  }}
                />
              )}
          </IconButton>
        </Box>
      </Box>
      {displayMenu && (
      <MobileMenu />
      )}
    </>
  );
}

export default Nav;
