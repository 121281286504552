import { React, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box, Typography, Button, TextField,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useQuery, gql } from '@apollo/client';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForward from '@mui/icons-material/ArrowForwardIos';
import { Helmet } from 'react-helmet';
import useForm from '../components/GateForm';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

const FORM_ENDPOINT = 'https://api.indesign.com.au/api/imap/index.php';

const mediaKitsQuery = gql`
  {
    mediaKits(pagination: { page:1, pageSize: 30 } sort: "Title:asc") {
      data {
        id
        attributes {
          Title
          File{
            data{
              attributes{
                url
              }
            }
          }
          brand{
            data{
              id
              attributes{
                Title
                Slug
              }
            }
          }
        }
      }
    }
  }
`;

function HeroSection() {
  return (
    <Box
      sx={{
        height: '50vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'background.light',
      }}
      display="flex"
    >
      <Box
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          display: 'flex',
          alignItems: 'flex-start',
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
        }}
      >
        <Typography
          component={motion.h1}
          sx={{

            display: 'flex',
            flexWrap: 'wrap',
          }}
          variants={AnimationVariants.enterDown}
          initial="exit"
          animate="enter"
          transition={{
            staggerChildren: 0.1,
            delayChildren: 0.3,
          }}
        >
          {StaggerText('Media Kits', 'h1')}
        </Typography>
      </Box>

    </Box>
  );
}

function KitList() {
  const { loading, error, data } = useQuery(mediaKitsQuery);

  const [expanded, setExpanded] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let formResponse;
  let formContainer;

  const additionalData = {
    sent: new Date().toISOString(),
  };

  const {
    handleSubmit, status, message, dataArray,
  } = useForm({
    additionalData,
  });

  if (status === 'success') {
    formContainer = document.getElementById(`formContainer_${dataArray.formID}`);
    formResponse = document.getElementById(`mediaKitContainer_${dataArray.formID}`);
    formContainer.style.display = 'none';
    formResponse.style.display = 'block';
  }

  if (status === 'error') {
    return (
      <>
        <div className="text-2xl">Something bad happened!</div>
        <div className="text-md">{message}</div>
      </>
    );
  }

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <Box
      component={motion.div}
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: 16,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
      }}
    >

      <Box
        sx={{
          height: 'fit-content',
          display: 'flex',
        }}
      >
        <Typography
          component={motion.p}
          variant="h5"
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
          sx={{
            width: '100%',
          }}
        >
          {StaggerText('Call-back Request', 'h5')}
        </Typography>
        <Box
          sx={{
            ml: {
              xs: 0,
              sm: 8,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
          >
            Interested in more info about our products and brands? We’ve got you covered. Select from our media kit resources, or request a call back to have one of our friendly team members get in touch to speak about opportunities for your brand.
          </Typography>
          <Box
            component={motion.div}
            variants={AnimationVariants.fadeIn}
            initial="exit"
            whileInView="enter"
            transition={{
              staggerChildren: 0.3,
              ease: 'easeOut',
            }}
            viewport={{ once: true }}
            sx={{
              mt: 8,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              component={Link}
              to="/contact"
            >
              Request Call-back
            </Button>
          </Box>
        </Box>

      </Box>

      <Box
        sx={{
          width: '100%',
          mt: 14,
          '& > *:not(:last-child)': {
            mb: 4,
          },
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        whileInView="enter"
        viewport={{ once: 'true' }}
        transition={{
          staggerChildren: 0.1,
          delayChildren: 0.5,
        }}
      >
        {
          data.mediaKits.data.map((mediaKit) => (
            <Accordion
              key={mediaKit.id}
              expanded={expanded === mediaKit.attributes.brand.data.attributes.Slug}
              onChange={handleChange(mediaKit.attributes.brand.data.attributes.Slug)}
              component={motion.div}
              variants={AnimationVariants.fadeIn}
            >
              <AccordionSummary
                aria-controls={`${mediaKit.attributes.brand.data.attributes.Slug}-content`}
                id={`${mediaKit.attributes.brand.data.attributes.Slug}-header`}
                expandIcon={<ArrowForward />}
                sx={{
                  paddingTop: 0,
                }}
              >
                <Typography variant="h5" component="p" sx={{ color: 'primary.light' }}>
                  {mediaKit.attributes.brand.data.attributes.Title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  id={`formContainer_${mediaKit.attributes.brand.data.attributes.Slug}`}
                  sx={{
                    display: 'block',
                  }}
                >
                  <Typography variant="body1" component="p" sx={{ mb: 4 }}>
                    For access to this media kit, please submit the form below.
                  </Typography>
                  <Box
                    component="form"
                    id={`${mediaKit.attributes.brand.data.attributes.Slug}`}
                    action={FORM_ENDPOINT}
                    onSubmit={handleSubmit}
                    method="POST"
                    sx={{
                      maxWidth: 550,
                    }}
                  >
                    <TextField
                      sx={{
                        width: '100%',
                        mb: 4,
                      }}
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      variant="standard"
                    />
                    <TextField
                      sx={{
                        width: '100%',
                        mb: 4,
                      }}
                      type="email"
                      placeholder="Email Address"
                      name="email"
                      variant="standard"
                    />
                    <TextField
                      sx={{
                        width: '100%',
                      }}
                      type="text"
                      placeholder="Company"
                      name="company"
                      variant="standard"
                    />
                    <input hidden name="formID" type="text" value={`${mediaKit.attributes.brand.data.attributes.Slug}`} readOnly />
                    {status !== 'loading' && (
                      <Button variant="contained" type="submit" color="primary" sx={{ mt: 4 }}>Submit</Button>
                    )}
                  </Box>
                </Box>
                <Box
                  id={`mediaKitContainer_${mediaKit.attributes.brand.data.attributes.Slug}`}
                  sx={{
                    display: 'none',
                  }}
                >
                  <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                    Thank you. Please download the media kit via the link below.
                  </Typography>
                  <KitItem
                    title={`Download - ${mediaKit.attributes.Title}`}
                    mediaKitURL={`http://cms.indesign.com.au${mediaKit?.attributes?.File?.data?.attributes?.url}`}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        }
      </Box>
    </Box>
  );
}

function KitItem({
  title, mediaKitURL,
}) {
  const [hover, setHover] = useState(false);
  const handleHover = () => {
    setHover(!hover);
  };
  return (
    <Box
      component={motion.div}
      variants={AnimationVariants.fadeIn}
      onMouseEnter={() => handleHover()}
      onMouseLeave={() => handleHover()}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          color: hover ? 'primary.light' : 'text.secondary',
          height: 'fit-content',
          py: 4,
        }}
        component={Link}
        to={mediaKitURL}
        target="_blank"
      >
        <Typography
          variant="h6"
          sx={{
            transition: '0.3s',
          }}
        >
          {title}
        </Typography>
        <ArrowDownwardIcon
          className="arrow-down"
          sx={{
            transition: '0.3s',
            ml: 2,
            color: hover ? 'primary.light' : 'text.secondary',
            opacity: hover ? 1 : 0,
          }}
        />
      </Box>
    </Box>
  );
}

KitItem.propTypes = {
  title: PropTypes.string.isRequired,
  mediaKitURL: PropTypes.string.isRequired,
};

function Services() {
  return (
    <>
      <Helmet>
        <title>Media Kits - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
      <KitList />
    </>
  );
}

export default Transition(Services);
