import { React, useState } from 'react';
import {
  Box, Typography, Button, TextField,
} from '@mui/material';
import * as FormData from 'form-data';
import Mailgun from 'mailgun.js';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

const mailgun = new Mailgun(FormData);

const mg = mailgun.client({ username: 'indesign', key: '65dc65d25f16f952f65ac698f13d10cf-4b98b89f-53de73dd' });

function HeroSection() {
  return (
    <Box
      sx={{
        height: '50vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'background.light',
      }}
      display="flex"
    >
      <Box
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          display: 'flex',
          alignItems: 'flex-start',
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
        }}
      >
        <Typography
          component={motion.h1}
          sx={{

            display: 'flex',
            flexWrap: 'wrap',
          }}
          variants={AnimationVariants.enterDown}
          initial="exit"
          animate="enter"
          transition={{
            staggerChildren: 0.1,
            delayChildren: 0.3,
          }}
        >
          {StaggerText('Get in Touch', 'h1')}
        </Typography>
      </Box>

    </Box>
  );
}

function ContactSection() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    // console.log(name, email, company, message);

    const formContainer = document.getElementById('formContainer');
    const formResponse = document.getElementById('formResponse');

    formContainer.style.display = 'none';
    formResponse.style.display = 'block';

    mg.messages.create('indesign.com.au', {
      from: 'Indesign Media Asia Pacific <noreply@indesign.com.au>',
      to: ['mediateam@indesign.com.au'],
      subject: 'Indesign Media - Corporate Site Enquiry',
      text: `Name - ${name} <br/><br/> Email Address - ${email} <br/><br/>Company - ${company} <br/><br/>Contact Number - ${phone}<br/><br/>Message - ${message}`,
      html: `Name - ${name} <br/><br/> Email Address - ${email} <br/><br/>Company - ${company} <br/><br/>Contact Number - ${phone}<br/><br/>Message - ${message}`,
    });
    // .then((msg) => console.log(msg)) // logs response data
    // .catch((err) => console.error(err)); // logs any error
  }

  return (
    <Box
      component={motion.div}
      sx={{
        position: 'relative',
        pt: 16,
        pb: 16,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        display: 'flex',
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
    >

      <Box
        sx={{
          width: '100%',
          height: 'fit-content',
          '& > *:not(:last-child)': {
            mb: 8,
          },
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{
              mb: 2,
            }}
          >
            Email
          </Typography>
          <Typography
            component={motion.a}
            variant="h5"
            variants={AnimationVariants.fadeIn}
            initial="exit"
            whileInView="enter"
            transition={{
              staggerChildren: 0.05,
              delayChildren: 0.2,
            }}
            sx={{
              width: '100%',
              transition: '0.3s',
              color: 'text.primary',
              '&:hover': {
                color: 'primary.light',
              },
            }}
            href="mailto:info@indesign.com.au"
          >
            info@indesign.com.au
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{ mb: 2 }}
          >
            Phone Number
          </Typography>
          <Typography
            component={motion.a}
            variant="h5"
            variants={AnimationVariants.fadeIn}
            initial="exit"
            whileInView="enter"
            transition={{
              staggerChildren: 0.05,
              delayChildren: 0.2,
            }}
            sx={{
              width: '100%',
              transition: '0.3s',
              color: 'text.primary',
              '&:hover': {
                color: 'primary.light',
              },
            }}
            href="tel:+61293680150"
          >
            (+61 2) 9368 0150
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{ mb: 2 }}
          >
            Address
          </Typography>
          <Typography
            component={motion.a}
            variant="h5"
            variants={AnimationVariants.fadeIn}
            initial="exit"
            whileInView="enter"
            transition={{
              staggerChildren: 0.05,
              delayChildren: 0.2,
            }}
            sx={{
              width: '100%',
              transition: '0.3s',
              color: 'text.primary',
              '&:hover': {
                color: 'primary.light',
              },
            }}
          >
            98 Holdsworth Street,
            <br />
            Woollahra NSW 2025
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          ml: {
            xs: 0,
            md: 8,
          },
          mt: {
            xs: 8,
            md: 0,
          },
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        id="formContainer"
        whileInView="enter"
        viewport={{ once: 'true' }}
        transition={{
          staggerChildren: 0.1,
          delayChildren: 0.2,
        }}
      >
        <Typography variant="body1" component="p" sx={{ mb: 4 }}>
          Want to get in touch? Indesign is proud to have a far-reaching presence across the Indo-Pacific. Whether you’re interested in advertising, collaborating or connecting, contact us to further discuss how we can work together.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            sx={{
              width: '100%',
              mb: 4,
            }}
            label="Your Name *"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            variant="standard"
          />
          <TextField
            sx={{
              width: '100%',
              mb: 4,
            }}
            label="Company"
            id="company"
            name="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            variant="standard"
          />
          <TextField
            sx={{
              width: '100%',
              mb: 4,
            }}
            label="Contact Number"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            variant="standard"
          />
          <TextField
            sx={{
              width: '100%',
              mb: 4,
            }}
            label="Email Address *"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            variant="standard"
          />
          <TextField
            sx={{
              width: '100%',
            }}
            label="Message *"
            id="message"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="standard"
            multiline
          />
          <Button variant="contained" type="submit" color="primary" sx={{ mt: 8 }}>Submit Message</Button>
        </form>
      </Box>
      <Box
        sx={{
          width: '100%',
          ml: {
            xs: 0,
            md: 8,
          },
          mt: {
            xs: 8,
            md: 0,
          },
          display: 'none',
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        id="formResponse"
        whileInView="enter"
        viewport={{ once: 'true' }}
        transition={{
          staggerChildren: 0.1,
          delayChildren: 0.2,
        }}
      >
        <Typography variant="body1" component="p" sx={{ mb: 4 }}>
          Thank you for your enquiry.
          <br />
          <br />
          A member from our team will be in contact with you shortly.
        </Typography>
      </Box>
    </Box>
  );
}

function Contact() {
  return (
    <>
      <Helmet>
        <title>Contact Us - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
      <ContactSection />
    </>
  );
}

export default Transition(Contact);
