/* eslint-disable import/no-mutable-exports */
import { createTheme } from '@mui/material';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1600,
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    useNextVariants: true,
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    h1: {
      lineHeight: '104.5%',
      letterSpacing: '-2px',
      fontWeight: 200,
      fontSize: 'max(4.5vw, 48px)',
    },
    h5: {
      fontSize: 'max(2vw, 24px)',
      fontWeight: 300,
      lineHeight: '113.5%',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 300,
      lineHeight: '113.5%',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: '113.5%',
      fontWeight: 200,
    },
    subtitle2: {
      fontSize: '0.85rem',
      lineHeight: '113.5%',
      fontWeight: 300,
    },
    body2: {
      fontSize: '0.85rem',
      lineHeight: '113.5%',
      fontWeight: 200,
    },
    caption: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      light: '#CCB78C',
      main: '#6F5135',
      dark: '#3C2C1E',
      contrastText: '#1A1A1A',
    },
    secondary: {
      light: '9F9F9F',
      main: '9F9F9F',
      dark: '9F9F9F',
      contrastText: '#1A1A1A',
    },
    background: {
      default: '#1A1A1A',
      light: '#21201F',
      paper: '#FFFFFF',
      dark: '#151515',
    },
    text: {
      primary: '#F1F1F1',
      secondary: '#959595',
      disabled: 'rgba(25,37,42,0.30)',
      hint: 'rgba(25,37,42,0.4)',
      contrastText: '#1A1A1A',
    },
    success: {
      main: '#60BA00',
    },
    divider: '#2B2B2B',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: () => ({
          '& .MuiInputBase-root::before': {
            borderBottom: '0.5px solid #FFFFFF19',
          },
          '& .MuiInputBase-root::after': {
            borderBottom: `2px solid ${theme.palette.primary.light}`,
          },
          '& .MuiFormLabel-root.Mui-focused': {
            color: theme.palette.primary.light,
          },
          '& .MuiInputBase-inputMultiline': {
            minHeight: 150,
          },
        }),
      },
    },
    MuiTabs: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        indicator: () => ({
          display: 'none',
        }),
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: () => ({
          borderRadius: 64,
          color: theme.palette.text.secondary,
          height: 32,
          padding: '6px 16px',
          transition: '0.3s',
          textTransform: 'none',
          minWidth: 'unset',
          minHeight: 'unset',
          '&:hover': {
            color: theme.palette.primary.light,
          },
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          '& > .MuiButtonBase-root, .Mui-disabled': {
            color: 'gray !important',
          },
          ...(ownerState.size === 'small' && {
            height: 32,
            fontSize: theme.typography.body2,
            fontWeight: '300',
          }),
          ...(ownerState.variant === 'contained' && {
            fontSize: theme.typography.body2,
            fontWeight: '300',
            padding: '6px 16px',
            textTransform: 'revert',
            boxShadow: 'none',
            borderRadius: '64px',
            alignItems: 'center',
            color: theme.palette.text.primary,
          }),
          ...(ownerState.variant === 'plain' && {
            fontSize: theme.typography.body2,
            color: theme.palette.text.primary,
            borderRadius: '64px',
            textTransform: 'none',
            '&:hover': {
              color: theme.palette.text.secondary,
              boxShadow: 'none',
              borderRadius: '64px',
            },
          }),
        }),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'solid' && {
            fontSize: theme.typography.body1,
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
              boxShadow: 'none',
              borderRadius: '64px',
              backgroundColor: theme.palette.primary.dark,
            },
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'filled' && {
            background: '#757576AF',
            width: 'fit-content',
            fontSize: theme.typography.caption,
          }),
        }),
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
        square: true,
      },
      styleOverrides: {
        root: () => ({
          borderBottom: '0.5px solid #FFFFFF46',
          background: 'transparent',
          px: 0,
          '&:before': {
            display: 'none',
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: () => ({
          backgroundColor: 'transparent',
          justifyContent: 'space-between',
          fontSize: theme.typography.body1,
          padding: theme.spacing(4, 0, 4, 0),
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(90deg)',
            color: theme.palette.text.primary,
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(-90deg)',
          },
          '& .MuiAccordionSummary-content': {
            margin: 0,
          },
        }),
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: () => ({
          padding: theme.spacing(0, 0, 4, 0),
        }),
      },
    },
  },
});

export default theme;
