/* eslint-disable */
import React from 'react';
import {
  Box, Typography,
} from '@mui/material';
import Nav from './Nav';
import ScrollToTop from './Router/ScrollToTop';
import Footer from './Footer';
import { motion } from 'framer-motion';
import AnimationVariants from './AnimationVariants';

export function Shell(props) {
  return (
    <>
    <ScrollToTop />
    <Nav />
    <Box>
      {props.children}
    </Box>
    <Footer />
    </>
  );
}

export function StaggerText(text, variant) {
  const textArray = text.split(" ");

  return(
    <>
    {textArray.map((t, i) => (
      <Typography 
      key={`staggerText-${i}`} 
      component="span"
      sx={{
        overflow: 'hidden',
        width: 'fit-content',
        pb: variant === 'h1' ? '0.9vw' : 'unset',
        display: 'inline-block',
        position: 'relative',
        height: 'auto',
      }}
      >
        <Typography 
        variant={variant} 
        component={motion.span}
        variants={AnimationVariants.enterDown}
        sx={{
          display: 'inline-block'
        }}
        transition={{
          ease: 'easeOut',
          duration: 0.3,
        }}
        >
          {t}&nbsp;
        </Typography>
      </Typography> 
    ))}
    </>
  )
}