import { React } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

function HeroSection() {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        p: {
          xs: 2,
          md: 4,
          lg: 8,
        },
      }}
    >
      <Box
        component={motion.h1}
        sx={{
          zIndex: 3,
          width: {
            xs: '100%',
            md: '70%',
          },
          display: 'flex',
          flexWrap: 'wrap',
        }}
        variants={AnimationVariants.enterDown}
        initial="exit"
        animate="enter"
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.3,
        }}
      >
        {StaggerText('404', 'h1')}
      </Box>
      <Box
        component={motion.h2}
        sx={{
          zIndex: 3,
          mt: -1,
          width: {
            xs: '100%',
            md: '70%',
          },
          display: 'flex',
          flexWrap: 'wrap',
        }}
        variants={AnimationVariants.enterDown}
        initial="exit"
        animate="enter"
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.3,
        }}
      >
        {StaggerText('Unable to find the page you were after.', 'h5')}
      </Box>

    </Box>
  );
}

function FourOhFour() {
  return (
    <>
      <Helmet>
        <title>Page not found - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
    </>
  );
}

export default Transition(FourOhFour);
