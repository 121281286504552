import React from 'react';
import {
  BrowserRouter,
} from 'react-router-dom';
import AnimatedRoutes from './AnimatedRoutes';
import ScrollToTop from './ScrollToTop';

function AppRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AnimatedRoutes />
    </BrowserRouter>
  );
}

export default AppRouter;
