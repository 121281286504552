import { React } from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function BrandItem({
  title, type, imgSrc, linkTo,
}) {
  return (
    <Box
      component={Link}
      to={linkTo}
      sx={{
        width: '100%',
        height: 'fit-content',
        overflow: 'hidden',
        '& > div .brand-item-img:hover': {
          scale: '1.05',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          mb: 2,
        }}
      >
        <img
          className="brand-item-img"
          src={imgSrc}
          alt=""
          style={{
            width: '100%',
            height: 'auto',
            transition: '0.3s',
          }}
        />
      </Box>
      <Typography variant="subtitle1" component="p" sx={{ color: 'text.primary', mb: 1 }}>{title}</Typography>
      <Typography variant="body1" component="p" sx={{ color: 'text.secondary' }}>{type}</Typography>
    </Box>
  );
}

BrandItem.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default BrandItem;
