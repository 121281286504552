import { React } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Moment from 'react-moment';
import DOMPurify from 'isomorphic-dompurify';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

const newsItem = gql`
  query GetNews($id: String!) {
    news(filters: {Slug: {eq: $id}}) {
       data {
        id
        attributes {
          Title
          Body
          publishedAt
          Excerpt
          Slug
          Image {
            data{
              attributes {
                url
                caption
              }
            }
          }
          Author {
            data{
              attributes {
                Name
              }
            }
          }
          Brand{
            __typename
            ...on BrandRelationResponseCollection{
              data{
                attributes{
                  Title
                }
              }
            }
          }
          SEO {
            __typename
            ...on ComponentSeoSeo {
              Title
              Description
            }
          }
          Meta {
            __typename
            ...on ComponentSeoMeta {
              Name
              Content
            }
          }
        }
      }
    }
  }
`;

function HeroSection() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(newsItem, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Box>
        <Box
          component={motion.h1}
          sx={{
            maxWidth: 800,
            position: 'relative',
            mx: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            mb: 4,
          }}
          variants={AnimationVariants.enterDown}
          initial="exit"
          animate="enter"
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.3,
          }}
        >
          {StaggerText(data.news.data[0].attributes.Title, 'h5')}
        </Box>
        <Box
          display="flex"
          sx={{
            maxWidth: 800,
            position: 'relative',
            mx: 'auto',
            mt: 'auto',
            mb: 8,
          }}
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.1,
            delayChildren: 1,
          }}
        >
          <Box
            sx={{
              mr: 8,
              mb: {
                xs: 8,
                md: 0,
              },
            }}
            component={motion.p}
            variants={AnimationVariants.fadeIn}
          >
            <Typography variant="subtitle1" component="span" color="textSecondary">
              Published By
              {' '}
            </Typography>
            <Typography variant="body1" component="span">
              {data.news.data[0].attributes.Author.data.attributes.Name}
              {' '}
            </Typography>
            <Typography variant="subtitle1" component="span" color="textSecondary">
              on
              {' '}
            </Typography>
            <Typography variant="body1" component="span">
              <Moment format="Do MMMM YYYY" date={data.news.data[0].attributes.publishedAt} />
            </Typography>
            <Typography variant="body1" component="span">
              {' | '}
              {data.news.data[0].attributes.Brand.data[0].attributes.Title}
              {'  '}
            </Typography>
          </Box>
        </Box>
      </Box>
      <motion.img
        src={`http://cms.indesign.com.au${data.news.data[0].attributes.Image.data.attributes.url}`}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
        }}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        animate="enter"
        transition={{
          delay: 0.3,
        }}
      />
      <Typography
        variant="subtitle1"
        component="p"
        color="textSecondary"
        sx={{
          maxWidth: 800,
          position: 'relative',
          mx: 'auto',
          mb: 8,
          mt: 2,
        }}
      >
        {data.news.data[0].attributes.Image.data.attributes.caption}
      </Typography>
    </>
  );
}

function Services() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(newsItem, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  let seoCheck = data?.news?.data[0]?.attributes?.SEO[0]?.Description;

  if (seoCheck === undefined) {
    seoCheck = 'The lastest news and announcements from Indesign Media Asia Pacific';
  }

  return (
    <>
      <Helmet>
        <title>{`${data.news.data[0].attributes.Title} - Indesign Media Asia Pacific`}</title>
        <meta name="description" content={seoCheck} />
      </Helmet>
      <Box
        sx={{
          maxWidth: 1440,
          position: 'relative',
          mx: 'auto',
          pt: 8,
          pb: 16,
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          mt: '54px',
        }}
      >
        <HeroSection />
        <Box
          sx={{
            maxWidth: 800,
            position: 'relative',
            mx: 'auto',
            '& > *:not(:last-child)': {
              mb: 4,
            },
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.news.data[0].attributes.Body) }} />

        </Box>

      </Box>
    </>
  );
}

export default Transition(Services);
