import { Box, Button, Typography } from '@mui/material';
import { React } from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: 'background.light',
      }}
    >
      <Box
        sx={{
          pt: 8,
          pb: 16,
          color: 'text.primary',
          display: {
            xs: 'block',
            md: 'flex',
          },
        }}
      >
        <Box
          sx={{
            mb: {
              xs: 8,
              md: 0,
            },
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{
              width: {
                xs: '100%',
                sm: '30%',
              },
              px: {
                xs: 2,
                lg: 8,
              },
              mb: 4,
            }}
          >
            Get In Touch
          </Typography>
          <Box
            sx={{
              width: '70%',
              px: {
                xs: 2,
                lg: 8,
              },
              '& > *:not(:last-child)': {
                mb: 4,
              },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{ color: 'text.primary' }}
            >
              Indesign is proud to have a far-reaching presence across the Indo-Pacific.
              Whether you’re interested in advertising, collaborating or connecting,
              contact us to further discuss how we can work together.
            </Typography>
            <Button variant="contained" component={Link} to="/contact" size="small">
              Contact Us
            </Button>
          </Box>
        </Box>
        <Box
          display="flex"
          sx={{
            width: '100%',
            justifyContent: {
              xs: 'flex-start',
              md: 'flex-end',
            },
            '& > *:not(last-child)': {
              mr: {
                xs: 4,
                md: 0,
              },
            },
          }}
        >
          <Box
            component="ul"
            sx={{
              mt: 0,
              px: {
                xs: 2,
                lg: 8,
              },
              listStyle: 'none',
            }}
          >
            <Box
              component="li"
              mb={4}
            >
              <Typography variant="subtitle1" component="p" color="textSecondary">
                Socials
              </Typography>
            </Box>
            <Box
              component="li"
            >
              <Typography
                variant="body1"
                component={Link}
                to="https://www.instagram.com/indesignlive/"
                sx={{
                  color: 'text.primary',
                  transition: '0.3s',
                  '& > *:hover': {
                    color: 'text.secondary',
                  },
                }}
              >
                Instagram
              </Typography>
            </Box>
            <Box
              component="li"
            >
              <Typography
                variant="body1"
                component={Link}
                to="https://www.linkedin.com/company/indesign-group"
                sx={{
                  color: 'text.primary',
                  transition: '0.3s',
                  '& > *:hover': {
                    color: 'text.secondary',
                  },
                }}
              >
                LinkedIn
              </Typography>
            </Box>
            <Box
              component="li"
            >
              <Typography
                variant="body1"
                component={Link}
                to="https://www.facebook.com/indesignlive"
                sx={{
                  color: 'text.primary',
                  transition: '0.3s',
                  '& > *:hover': {
                    color: 'text.secondary',
                  },
                }}
              >
                Facebook
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              px: {
                xs: 2,
                lg: 8,
              },
              gap: 2,
            }}
          >
            <Typography variant="subtitle1" component="p" color="textSecondary" sx={{ mb: 2 }}>
              Office
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href="tel:+61293680150"
              sx={{
                width: '100%',
                transition: '0.3s',
                color: 'text.primary',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
            >
              (61 2) 9368 0150
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href="mailto:info@indesign.com.au"
              sx={{
                width: '100%',
                transition: '0.3s',
                color: 'text.primary',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
            >
              info@indesign.com.au
            </Typography>
            <Typography variant="body1" component="p">
              98 Holdsworth Street,
              {' '}
              <br />
              Woollahra NSW 2025
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="subtitle1"
        component="p"
        sx={{
          mt: 16,
          px: {
            xs: 2,
            lg: 8,
          },
          pb: 8,
          color: 'text.primary',
        }}
      >
        Indesign Media Asia Pacific.
      </Typography>
    </Box>
  );
}

export default Footer;
