import { React, useState } from 'react';
import {
  Box, Typography, Tabs, Tab,
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';
import BrandItem from '../components/BrandItem';

const BrandsQuery = gql`
  {
    brands(pagination: { page:1, pageSize: 30 } sort: "Title:asc") {
      data {
        id
        attributes {
          Title
          Slug
          Image {
            data{
              attributes {
                url
              }
            }
          }
          product_type{
            data{
              attributes{
                Title
              }
            }
          }
          product_industry{
            data{
              attributes{
                Title
                Description
                industrySlug
              }
            }
          }
        }
      }
    }
    productIndustries{
      data{
        id
        attributes{
          Title
          industrySlug
        }
      }
    }
  }
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function HeroSection({ value, handleChange }) {
  const { loading, error, data } = useQuery(BrandsQuery);

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  const brandsItemCount = data.brands.data.length;

  return (
    <Box
      sx={{
        height: '50vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'background.light',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          marginTop: 'auto',
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          pb: 8,
        }}
      >
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            alignItems: 'flex-start',
            mb: 8,
          }}
        >
          <Typography
            component={motion.h1}
            sx={{

              display: 'flex',
              flexWrap: 'wrap',
            }}
            variants={AnimationVariants.enterDown}
            initial="exit"
            animate="enter"
            transition={{
              staggerChildren: 0.1,
              delayChildren: 0.3,
            }}
          >
            {StaggerText('Brands', 'h1')}
          </Typography>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            variants={AnimationVariants.fadeIn}
            initial="exit"
            animate="enter"
            component={motion.p}
            transition={{
              delay: 0.6,
            }}
          >
            {brandsItemCount}
          </Typography>
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="All" {...a11yProps(0)} />
          {
            data.productIndustries.data.map((brandType) => (
              <Tab label={brandType.attributes.Title} {...a11yProps(brandType.id)} />
            ))
          }
          {/*
          <Tab label="Architecture & Design" {...a11yProps(1)} />
          <Tab label="Habitus" {...a11yProps(2)} />
          <Tab label="Indesign" {...a11yProps(2)} />
          */}
        </Tabs>
      </Box>
    </Box>
  );
}

HeroSection.propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

function Brands() {
  const { loading, error, data } = useQuery(BrandsQuery);

  const [value, setValue] = useState(0);

  let wrapperContainer = document.getElementsByClassName('brandContainer');

  const handleChange = (event, newValue) => {
    wrapperContainer = document.getElementsByClassName('brandContainer');
    for (let i = 0; i < wrapperContainer.length; i += 1) {
      wrapperContainer[i].style.display = 'none';
    }
    setValue(newValue);
    if (newValue === 0) {
      wrapperContainer = document.getElementsByClassName('brandContainer');
      for (let i = 0; i < wrapperContainer.length; i += 1) {
        wrapperContainer[i].style.display = 'block';
      }
    }
    if (newValue === 1) {
      wrapperContainer = document.getElementsByClassName('architecture');
      for (let i = 0; i < wrapperContainer.length; i += 1) {
        wrapperContainer[i].style.display = 'block';
      }
    }
    if (newValue === 2) {
      wrapperContainer = document.getElementsByClassName('interior-design');
      for (let i = 0; i < wrapperContainer.length; i += 1) {
        wrapperContainer[i].style.display = 'block';
      }
    }
    if (newValue === 3) {
      wrapperContainer = document.getElementsByClassName('travel');
      for (let i = 0; i < wrapperContainer.length; i += 1) {
        wrapperContainer[i].style.display = 'block';
      }
    }
  };

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;
  return (
    <>
      <Helmet>
        <title>Brands - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection value={value} handleChange={handleChange} />
      <Box
        sx={{
          pt: 8,
          pb: 16,
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        intial="exit"
        animate="enter"
        transition={{
          delay: 0.8,
        }}
      >
        <Box
          sx={{
            margin: '0 auto',
            display: 'grid',
            gap: '64px 16px',
            gridTemplateColumns: {
              xs: 'repeat(1,1fr)',
              md: 'repeat(3,1fr)',
            },
          }}
        >
          {
            data.brands.data.map((brands) => (
              <div className={`brandContainer ${brands?.attributes?.product_industry?.data?.attributes?.industrySlug}`}>
                <BrandItem
                  key={brands.id}
                  title={brands.attributes.Title}
                  type={brands?.attributes?.product_type?.data?.attributes?.Title}
                  imgSrc={`http://cms.indesign.com.au${brands?.attributes?.Image?.data?.attributes?.url}`}
                  linkTo={`/brands/${brands.attributes.Slug}`}
                />
              </div>
            ))
          }
        </Box>
      </Box>
    </>
  );
}

export default Transition(Brands);
