import * as React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import {
  Box, Typography, Button, TextField, Collapse,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import DOMPurify from 'isomorphic-dompurify';
import useForm from '../components/GateForm';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';
// import NewsItem from '../components/NewsItem';

const FORM_ENDPOINT = 'https://api.indesign.com.au/api/imap/index.php';

const brandItem = gql`
  query GetBrand($id: String!) {
    brands(filters: {Slug: {eq: $id}}) {
      data {
        id
        attributes {
          Slug
          Title
          publishedAt
          Description
          VisitSite
          JoinNewsletter
          Image {
            data{
              attributes {
                url
                caption
              }
            }
          }
          news(pagination: { page:1, pageSize: 3 } sort: "createdAt:desc"){
            data{
              id
              attributes {
                Title
                publishedAt
                Slug
                Image {
                  data{
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          media_kit{
            data{
              attributes{
                Title
                File{
                  data{
                    attributes{
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

function HeroSection() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { id } = useParams();

  const { loading, error, data } = useQuery(brandItem, {
    variables: { id },
  });

  let formResponse;
  let formContainer;

  const additionalData = {
    sent: new Date().toISOString(),
  };

  const {
    handleSubmit, status, message, dataArray,
  } = useForm({
    additionalData,
  });

  if (status === 'success') {
    formContainer = document.getElementById(`formContainer_${dataArray.formID}`);
    formResponse = document.getElementById(`mediaKitContainer_${dataArray.formID}`);
    formContainer.style.display = 'none';
    formResponse.style.display = 'block';
  }

  if (status === 'error') {
    return (
      <>
        <div className="text-2xl">Something bad happened!</div>
        <div className="text-md">{message}</div>
      </>
    );
  }

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <>
      <Box
        sx={{
          maxWidth: 800,
          mx: 'auto',
        }}
      >
        <Box
          component={motion.h1}
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            mb: 2,
          }}
          variants={AnimationVariants.enterDown}
          initial="exit"
          animate="enter"
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.3,
          }}
        >
          {StaggerText(data.brands.data[0].attributes.Title, 'h1')}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            mb: 4,
            mr: {
              xs: 0,
              sm: 8,
            },
            '& > *:not(:last-child)': {
              mr: {
                xs: 0,
                sm: 4,
              },
              mb: {
                xs: 0,
                sm: 4,
              },
            },
          }}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          animate="enter"
          component={motion.div}
          transition={{ delay: 0.5 }}
        >
          {data?.brands?.data[0]?.attributes?.JoinNewsletter ? (
            <Typography
              variant="subtitle1"
              sx={{
                transition: '0.3s',
                color: 'text.secondary',
                height: 'fit-content',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
              component={Link}
              target="_blank"
              to={data?.brands?.data[0]?.attributes?.JoinNewsletter}
            >
              Join Newsletter
            </Typography>
          ) : null}
          {data?.brands?.data[0]?.attributes?.media_kit?.data ? (
            <Typography
              expand={expanded}
              onClick={handleExpandClick}
              variant="subtitle1"
              sx={{
                cursor: 'pointer',
                transition: '0.3s',
                color: 'text.secondary',
                height: 'fit-content',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
            >
              Media Kit
            </Typography>
          ) : null}
          {data?.brands?.data[0]?.attributes?.VisitSite ? (
            <Typography
              variant="subtitle1"
              sx={{
                transition: '0.3s',
                color: 'text.secondary',
                height: 'fit-content',
                '&:hover': {
                  color: 'primary.light',
                },
              }}
              component={Link}
              target="_blank"
              to={data?.brands?.data[0]?.attributes?.VisitSite}
            >
              Visit Site
            </Typography>
          ) : null}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
              mb: 4,
              mr: {
                xs: 0,
                sm: 8,
              },
              '& > *:not(:last-child)': {
                mr: {
                  xs: 0,
                  sm: 4,
                },
                mb: {
                  xs: 0,
                  sm: 4,
                },
              },
            }}
            variants={AnimationVariants.fadeIn}
            initial="exit"
            animate="enter"
            component={motion.div}
            transition={{ delay: 0.5 }}
          >
            <Box
              id={`formContainer_${data?.brands?.data[0]?.attributes?.Slug}`}
              sx={{
                display: 'block',
              }}
            >
              <Typography variant="body1" component="p" sx={{ mb: 4 }}>
                For access to this media kit, please submit the form below.
              </Typography>
              <form
                id={`${data?.brands?.data[0]?.attributes?.Slug}`}
                action={FORM_ENDPOINT}
                onSubmit={handleSubmit}
                method="POST"
              >
                <TextField
                  sx={{
                    width: '100%',
                    mb: 4,
                  }}
                  type="text"
                  placeholder="Your Name"
                  name="name"
                />
                <TextField
                  sx={{
                    width: '100%',
                    mb: 4,
                  }}
                  type="email"
                  placeholder="Email Address"
                  name="email"
                />
                <TextField
                  sx={{
                    width: '100%',
                  }}
                  type="text"
                  placeholder="Company"
                  name="company"
                />
                <input hidden name="formID" type="text" value={`${data?.brands?.data[0]?.attributes?.Slug}`} readOnly />
                {status !== 'loading' && (
                  <Button variant="contained" type="submit" color="primary" sx={{ mt: 4 }}>Submit</Button>
                )}
              </form>
            </Box>
            <Box
              id={`mediaKitContainer_${data?.brands?.data[0]?.attributes?.Slug}`}
              sx={{
                display: 'none',
              }}
            >
              <Typography variant="body1" component="p" sx={{ mb: 1 }}>
                Thank you. Please download the media kit via the link below.
              </Typography>
              <Button
                sx={{ mt: 4 }}
                variant="contained"
                color="primary"
                component={Link}
                target="_blank"
                to={`http://cms.indesign.com.au${data?.brands?.data[0]?.attributes?.media_kit?.data?.attributes?.File?.data?.attributes?.url}`}
              >
                {`Download - ${data?.brands?.data[0]?.attributes?.media_kit?.data?.attributes?.Title}`}
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Box>
      <motion.img
        src={`http://cms.indesign.com.au${data?.brands?.data[0]?.attributes?.Image?.data?.attributes?.url}`}
        alt=""
        style={{
          width: '100%',
          height: 'auto',
          marginBottom: 64,
        }}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        animate="enter"
        transition={{
          delay: 0.3,
        }}
      />
    </>
  );
}

/* function NewsBlock() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(brandItem, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;

  if (error) return <p>Error :(</p>;

  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        pt: 8,
        pb: 8,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: 8,
        }}
      >
        <Box
          component={motion.div}
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          {StaggerText(`${data.brands.data[0].attributes.Title} News`, 'h5')}
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          margin: '0 auto',
          display: 'grid',
          gap: 2,
          gridTemplateColumns: {
            xs: 'repeat(1,1fr)',
            md: 'repeat(3,1fr)',
          },
        }}
      >
        {
          data.brands.data.map((brands) => (
            brands.attributes.news.data.map((news) => (
              <NewsItem
                key={news.id}
                title={news.attributes.Title}
                date={news.attributes.publishedAt}
                imgSrc={`http://cms.indesign.com.au${news.attributes.Image.data.attributes.url}`}
                linkTo={`/news/${news.attributes.Slug}`}
              />
            ))
          ))
        }
      </Box>
    </Box>
  );
} */

function Brand() {
  const { id } = useParams();

  const { loading, error, data } = useQuery(brandItem, {
    variables: { id },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <Helmet>
        <title>{`${data.brands.data[0].attributes.Title} - Indesign Media Asia Pacific`}</title>
      </Helmet>
      <Box
        sx={{
          maxWidth: 1440,
          position: 'relative',
          mx: 'auto',
          pt: 8,
          pb: 16,
          px: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          mt: '54px',
        }}
      >
        <HeroSection />
        <Box
          sx={{
            maxWidth: 800,
            mx: 'auto',
            '& > *:not(:last-child)': {
              mb: 4,
            },
          }}
        >
          <Typography variant="subtitle1" component="p">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.brands.data[0].attributes.Description) }} />
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Transition(Brand);
