const AnimationVariants = {
  staggerAnimation: {
    enter: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.6,
      },
    },
    exit: {
      opacity: 0,
    },
  },
  enterLeft: {
    enter: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: -10,
    },
  },
  enterRight: {
    enter: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
      x: 10,
    },
  },
  enterDown: {
    enter: {
      y: 0,
    },
    exit: {
      y: '150%',
    },
  },
  enterUp: {
    enter: {
      y: 0,
    },
    exit: {
      y: -50,
    },
  },
  fadeIn: {
    enter: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  },
};

export default AnimationVariants;
