import { React } from 'react';
import { motion } from 'framer-motion';
import { Box } from '@mui/material';

// Transition component that will display every time a user switches page

const slideUp = {
  enter: {
    y: '100%',
  },
  exit: {
    y: '0',
  },
};

function Transition(Content) {
  return function () {
    return (
      <>
        <Content />
        {/* Slide In */}
        <Box
          sx={{
            position: 'fixed',
            inset: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 99999,
            transfromOrigin: 'bottom',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
          }}
          component={motion.div}
          variants={slideUp}
          initial="enter"
          animate="enter"
          exit="exit"
          transition={{
            staggerChildren: 0.1,
            stiffness: 100,
          }}
        >
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
        </Box>
        {/* Slide Out */}
        <Box
          sx={{
            position: 'fixed',
            inset: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 99999,
            transfromOrigin: 'bottom',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
          }}
          component={motion.div}
          variants={slideUp}
          initial="exit"
          animate="enter"
          exit="enter"
          transition={{
            delay: 0.8,
            staggerChildren: 0.1,
            stiffness: 100,
          }}
        >
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
          <Box
            component={motion.div}
            variants={slideUp}
            sx={{
              height: '100%',
              display: 'block',
              backgroundColor: 'primary.dark',
            }}
            transition={{
              ease: 'easeOut',
            }}
          />
        </Box>
      </>
    );
  };
}

export default Transition;
