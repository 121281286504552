import { React, useRef } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { motion, useTransform, useScroll } from 'framer-motion';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Transition from '../components/Transition';
import AnimationVariants from '../components/AnimationVariants';
import { StaggerText } from '../components/Layouts';

function HeroSection() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
  const textY = useTransform(scrollYProgress, [0, 1], ['0%', '100%']);
  return (
    <Box
      ref={containerRef}
      sx={{
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
      }}
      display="flex"
    >
      <Box
        component={motion.h1}
        sx={{
          marginTop: 'auto',
          zIndex: 3,
          p: {
            xs: 2,
            md: 4,
            lg: 8,
          },
          width: {
            xs: '100%',
            md: '70%',
          },
          display: 'flex',
          flexWrap: 'wrap',
        }}
        style={{ y: textY }}
        variants={AnimationVariants.enterDown}
        initial="exit"
        animate="enter"
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.5,
        }}
      >
        {StaggerText('The Indesign Media umbrella is ready to bring your products right into your market\'s lap.', 'h1')}
      </Box>
      <motion.div
        style={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          y: backgroundY,
          inset: 0,
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            height: '100vh',
            width: '100vw',
            zIndex: 1,
            background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 61%, rgba(0, 0, 0, 0.70) 71%), linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0) 9%)',
          }}
        />
        <motion.img
          src="images/services/hero.jpg"
          alt=""
          style={{
            position: 'absolute',
            inset: 0,
            margin: 'auto',
            width: 'auto',
            height: '100vh',
            zIndex: 0,
          }}
          initial={{ scale: 1.3 }}
          animate={{ scale: 1.15 }}
          transition={{
            ease: 'anticipate',
            duration: 1,
          }}
        />
      </motion.div>

    </Box>
  );
}

function ServiceList() {
  return (
    <Box
      component={motion.div}
      sx={{
        willChange: 'transform',
        position: 'relative',
        pt: 16,
        pb: 16,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'row',
        },
      }}
    >

      <Box
        sx={{
          position: {
            xs: 'initial',
            sm: 'sticky',
          },
          top: 64,
          width: '100%',
          height: 'fit-content',
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          color="textSecondary"
          sx={{
            mb: 4,
          }}
        >
          Services we Offer
        </Typography>
        <Typography
          component={motion.p}
          variant="h5"
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
          sx={{
            width: '100%',
          }}
        >
          {StaggerText('Throughout our multiple platforms across the Indo-Pacific, we offer you access to the widest and most engaged audience in the region.', 'h5')}
        </Typography>
        <Box
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          transition={{
            staggerChildren: 0.3,
            ease: 'easeOut',
          }}
          viewport={{ once: true }}
          sx={{
            mt: 8,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            to="/contact"
            component={Link}
          >
            Talk to Us
          </Button>
        </Box>

      </Box>

      <Box
        sx={{
          width: '100%',
          ml: {
            xs: 0,
            sm: 8,
          },
          mt: {
            xs: 8,
            sm: 0,
          },
          '& > *::first-of-type': {
            pt: 0,
          },
        }}
        component={motion.div}
        variants={AnimationVariants.fadeIn}
        initial="exit"
        whileInView="enter"
        viewport={{ once: 'true' }}
        transition={{
          staggerChildren: 0.1,
          delayChildren: 0.2,
        }}
      >
        <ServiceItem
          title="Media Campaigns"
          description="Our media campaigns set us apart in the design market through their innovative approach, meticulous research, and deep understanding of our audience's preferences. We blend captivating visuals with insightful content, delivering a unique and memorable experience. By leveraging cutting-edge technologies and platforms, we ensure maximum reach and engagement, surpassing our competitors. Our campaigns resonate with the design community, sparking conversations and driving meaningful connections, ultimately solidifying our position as industry leaders in media excellence."
        />
        <ServiceItem
          title="Content Amplification"
          description="The key to capturing the attention of your target audience is to ensure you’re reaching them through multiple touchpoints. At Indesign Media, we can help you create strategic cross platform media campaigns that allow you to get your story out to your target audience around the entire Indo-Pacific region. We can work with you to create campaigns that are tailored to your brand’s objectives and can increase redibility, consumer loyalty and sales. You’ve put in the work, now let us show it to the world."
        />
        <ServiceItem
          title="Lead Generation"
          description="We understand the importance of keeping your sales pipeline
          active and the difficulties in reaching the right audience
          to generate new leads. This is why we want to help you
          create leads that are interested in your brand so you can
          continue focusing on creating products they will love.
          Work with us today to expand your audience."
        />
        <ServiceItem
          title="Events and Activations"
          description="Events are an ideal way to get more brand exposure and connections.
          With our knowledge and experience from over two decades of events,
          we can help you to wow your audience and ensure your activities create a
          positive lasting impression. With options of joining our industry leading
          events, or using our expert team to refine your own, get in touch to discuss
          your brand’s goals and how we can work together."
        />
        <ServiceItem
          title="Content Marketing"
          description="The marketing landscape is ever changing. For your brand to succeed,
          strategic communication is key. Partner with us to creatively identify the
          elements that make your brand unique and create a strategically crafted campaign
          to elevate your story. Amplified via the power of our cross-platform ecosystem,
          expertise and data – let us help you inform audience
          journeys and drive conversion behaviours."
        />
        <ServiceItem
          title="Bespoke Video Creation"
          description="Our video creation service is tailored to your brand’s objectives.
          Our aim is to transform your content into video that will
          resonate with your target audience. We offer support from pre- to
          post-production with a team that is focused on creating engaging
          videos for your brand that can be used for social media content,
          digital advertising and more."
        />
      </Box>
    </Box>
  );
}

function ServiceItem({
  title, description,
}) {
  return (
    <Box
      sx={{
        borderBottom: '0.5px solid #FFFFFF19',
        pt: 4,
        pb: 4,
        '& > *': {
          mb: 4,
          width: '100%',
        },
      }}
      component={motion.div}
      variants={AnimationVariants.fadeIn}
    >
      <Typography
        variant="h5"
        sx={{
          color: 'primary.light',
          mb: 4,
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
      >
        {description}
      </Typography>
    </Box>
  );
}

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function OurReach() {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end start'],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '20%']);
  return (
    <Box
      ref={containerRef}
      sx={{
        backgroundColor: 'background.light',
        pt: 8,
        pb: 16,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
        '& > *:not(:last-child)': {
          mb: 16,
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          aspectRatio: '16 / 9',
          width: '100%',
          mb: 8,
        }}
      >
        <motion.img
          style={{
            position: 'absolute',
            width: '100%',
            height: 'auto',
            sclae: 1.5,
            bottom: 0,
            margin: 'auto',
            y: backgroundY,
          }}
          src="images/services/talking-to-a-panel.JPG"
          alt=""
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column-reverse',
            sm: 'row',
          },
        }}
      >
        <Box
          display="flex"
          sx={{
            width: '100%',
            flexWrap: 'wrap',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            mr: {
              xs: 0,
              sm: 8,
            },
          }}
          component={motion.div}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          <Box
            sx={{
              width: '100%',
              mb: {
                xs: 8,
                md: 8,
              },
            }}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <Typography variant="h1" component="p" sx={{ color: 'primary.light', mb: 2 }}>550,000+</Typography>
            <Typography variant="subtitle1" component="p" color="textSecondary">
              Audience Reached Through
              {' '}
              <br />
              our Platforms
            </Typography>
          </Box>
          <Box
            sx={{
              width: '50%',
              mb: {
                xs: 8,
                md: 0,
              },
            }}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <Typography variant="h1" component="p" sx={{ color: 'primary.light', mb: 2 }}>20+</Typography>
            <Typography variant="subtitle1" component="p" color="textSecondary">
              Years in the Architecture,
              {' '}
              <br />
              Design & Travel space.
            </Typography>
          </Box>
          <Box
            sx={{ width: '50%' }}
            component={motion.div}
            variants={AnimationVariants.fadeIn}
          >
            <Typography variant="h1" component="p" sx={{ color: 'primary.light', mb: 2 }}>2,100+</Typography>
            <Typography variant="subtitle1" component="p" color="textSecondary">
              Amazing Clients
              {' '}
              <br />
              We Have Worked With
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: 'fit-content',
          }}
        >
          <Typography
            variant="subtitle1"
            component="p"
            color="textSecondary"
            sx={{
              mb: 4,
            }}
          >
            Our Reach
          </Typography>
          <Typography
            component={motion.p}
            variant="h5"
            variants={AnimationVariants.enterDown}
            initial="exit"
            whileInView="enter"
            viewport={{ once: 'true' }}
            transition={{
              staggerChildren: 0.05,
              delayChildren: 0.2,
            }}
            sx={{
              width: '100%',
              mb: {
                xs: 8,
                sm: 0,
              },
            }}
          >
            {StaggerText('Indesign\'s reach spans the Asia-Pacific, driven by a relentless pursuit of growth and expansion, ensuring we engage the most significant design markets.', 'h5')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function PlatformItem({ title, description }) {
  return (
    <Box
      sx={{
        borderTop: '0.5px solid #FFFFFF19',
        pt: 4,
        '& > *:not(:last-child)': {
          mb: 4,
        },
        width: '100%',
        mb: {
          xs: 8,
          md: 0,
        },
      }}
      component={motion.div}
      variants={AnimationVariants.fadeIn}
    >
      <Typography
        variant="h5"
        component="p"
        sx={{
          color: 'primary.light',
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="p"
      >
        {description}
      </Typography>
    </Box>

  );
}

PlatformItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function Platforms() {
  return (
    <Box
      component={motion.div}
      sx={{
        position: 'relative',
        pt: 16,
        pb: 16,
        zIndex: 3,
        px: {
          xs: 2,
          md: 4,
          lg: 8,
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          mb: 16,
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },
          width: '100%',
          '& > *:not(:last-child)': {
            mb: {
              xs: 2,
              sm: 0,
            },
          },
        }}
      >
        <Box
          component={motion.div}
          variants={AnimationVariants.enterDown}
          initial="exit"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{
            staggerChildren: 0.05,
            delayChildren: 0.2,
          }}
        >
          {StaggerText('Platforms we Offer', 'h5')}
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          variants={AnimationVariants.fadeIn}
          initial="exit"
          to="/brands"
          whileInView="enter"
          viewport={{ once: 'true' }}
          transition={{ delay: 0.5 }}
        >
          View Platforms

        </Button>
      </Box>
      <Box
        component={motion.div}
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
          },
          columnGap: 4,
          rowGap: 8,
        }}
        variants={AnimationVariants.fadeIn}
        inital="exit"
        whileInView="enter"
        viewport={{ once: true }}
        transition={{
          staggerChildren: 0.05,
          delayChildren: 0.2,
        }}
      >
        <PlatformItem title="Digital" description="Covering the latest projects,  stories, events and in depth interviews, our digital platforms have their finger on the pulse, driving the industry forward with daily updates that cover every corner of our region." />
        <PlatformItem title="Print" description="With six mastheads across all sectors of the industry, the Indesign Media umbrella offers print media as an enduring voice, connecting brands and consumers together through compelling narratives and beautiful designs." />
        <PlatformItem title="Events" description="Disrupting the industry with innovative formats and pioneering conversation series, Indesign’s events set the standard for awards, seminars, exhibitions and showroom activations, to bring the industry together like no other." />
        <PlatformItem title="Content" description="Connected to a wide network of editors, contributors and industry experts, Indesign’s in-house content service, C-GEN, creates extraordinary, thumb-stopping content and strategically places it in front of the people that really matter." />
        <PlatformItem title="Television" description="Whether it’s our own program or we’re featuring in shows covering architecture and design, we’re proud to diversify our offering through television, expanding our reach and connecting to a greater audience than ever before." />
        <PlatformItem title="Recruitment" description="With global connections to the industry’s best, Recruitment Indesign - powered by Careers Indesign and the Indesign Media umbrella - works to recognise, build and place talent, to ensure the collective growth of our wider industry." />
      </Box>
    </Box>
  );
}

function Services() {
  return (
    <>
      <Helmet>
        <title>Services - Indesign Media Asia Pacific</title>
      </Helmet>
      <HeroSection />
      <ServiceList />
      <OurReach />
      <Platforms />
    </>
  );
}

export default Transition(Services);
